module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-33633422-2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Photography art by Ben Hubert","short_name":"Photography","description":"Photography art by Benjamin Hubert","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"logos/logo_1_square.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"cfcc61cb443bbe2ff218414abebe7627"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
